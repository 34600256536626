import type { ISession } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { SqueegeeClientSocketApi } from './SqueegeeClientSocketApi';

export const getHeaders = (session?: ISession, forceJson = false) => {
    const headers: Record<string, string> = {
        'Authorization': session ? `Bearer ${session.key}:${session.value}` : '',
        'data-email': ApplicationState.dataEmail || '',
        'client-version': ApplicationState.version,
        'client-id':
            (SqueegeeClientSocketApi.instance &&
                SqueegeeClientSocketApi.instance.server &&
                SqueegeeClientSocketApi.instance.server.clientId) ||
            '',
        'device-id': ApplicationState.deviceId,
        'Accept-Language': ApplicationState.account?.language,
    };
    if (forceJson) {
        headers['Content-Type'] = 'application/json';
    }
    return headers;
};
